import React from 'react';
import { useSelector } from 'react-redux';

const useCryptoInterest = ({apyRate}) => {
    let investRate = apyRate || useSelector(state => state.app.contentVariables.max_term_rate);
    const loyaltyBenefits = useSelector(state => state?.auth?.loyaltyBenefits);
    const isAuth = useSelector(state => !!state?.auth?.token);

    if (isAuth) {
        investRate = parseFloat(investRate) + (investRate * loyaltyBenefits?.Benefits?.InvestIncreaseInterestRate / 100);
    }

    return [investRate];
};

export default useCryptoInterest;