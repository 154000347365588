export const numReview = 1555;
export const pointRating = 4.7;

export const reviews = {
  0: {
    star: '5',
    title: 'Great platform',
    desc: 'Great platform, APY and other investments. if you want to consolidate debt, finance a large purchase, or cover an emergency expense, a personal loan can be useful. However, many personal loan lenders require borrowers to have good or excellent credit, ...',
    date: 'June 14, 2022',
    auth: 'Cekselim',
    link: 'https://www.trustpilot.com/reviews/62a8431c0031539b613f0852',
    avatar: null,
  },
  1: {
    star: '5',
    title: 'Excellent platform',
    desc: 'So far when there\'s a bug to be fix and they get to solving the issue you continue to use the application effectively as it was intended for it to be used and it works.',
    date: 'June 14, 2022',
    auth: 'Enrique Murrietta',
    link: 'https://www.trustpilot.com/reviews/62a7d7770031539b613edc2c',
    avatar: null,
  },
  2: {
    star: '5',
    title: 'Excellent App',
    desc: 'I have just started meaning I\'m new to MyConstant. Overall i\'m learning a lot. I very much glad I took a chance on this app. I would recommend my friends, family, to anyone interested. I appreciate the knowledge and info being presented.',
    date: 'June 11, 2022',
    auth: 'LaRon Sisk',
    link: 'https://www.trustpilot.com/reviews/62a47e0b6d54f9210712d034',
    avatar: null
  },
  3: {
    star: '5',
    title: 'This app is a great idea',
    desc: 'This looks like a great idea. I can\'t wait til I pass kyc to start lending!',
    date: 'June 9, 2022',
    auth: 'Christopher La Bash',
    link: 'https://www.trustpilot.com/reviews/62a178156d54f92107106e64',
    avatar: null
  },
  4: {
    star: '5',
    title: 'Great so far',
    desc: 'If you use MyConstant and can\'t wait to use your money only when you need it. This is a way to make your money work for you and live off the interest. Or if you want cheaper crypto loans with an awesome rewards program. This is the place for you.',
    date: 'June 6, 2022',
    auth: 'C Milli',
    link: 'https://www.trustpilot.com/reviews/629d9aa5d5573095630c0e17',
    avatar: null
  },
  5: {
    star: '5',
    title: 'My personal experience',
    desc: 'My personal experience, I really like the idea of saving money and making money. As I type this fifty words my principal is gaining interest and my interest is gaining interest. Win win in my eyes. I\'m still learning how everything works but ....',
    date: 'June 6, 2022',
    auth: 'Terique Bragg',
    link: 'https://www.trustpilot.com/reviews/629d56d535343b8f5b621994',
    avatar: null
  },
  6: {
    star: '5',
    title: 'Investing in MyConstant',
    desc: 'I\'ve been investing in MyConstant for the last 3 months + and have had a great experience. Top-notch rates for stable coins and a very helpful team of customer representatives. Great selection of investment opportunities.',
    date: 'June 6, 2022',
    auth: 'Robert',
    link: 'https://www.trustpilot.com/reviews/629cfcb435343b8f5b61f76a',
    avatar: null
  },
  7: {
    star: '5',
    title: 'MyConstant app has been very useful',
    desc: 'My constant app has been a very useful and fun way to get into the crypto/stock market. They have fun ways to earn free rewards.',
    date: 'June 6, 2022',
    auth: 'Kirsten Maurizzio',
    link: 'https://www.trustpilot.com/reviews/629ce61e35343b8f5b61e71e',
    avatar: null
  },
  8: {
    star: '5',
    title: 'I love this app and it helped me understand',
    desc: 'I love this app—It helped me understand investment\'s and NFTs so quickly. What a great tool!',
    date: 'June 5, 2022',
    auth: 'Dustin Clough',
    link: 'https://www.trustpilot.com/reviews/629cb6abd5573095630bade6',
    avatar: null
  },
  9: {
    star: '5',
    title: 'Basic and easy',
    desc: 'Basic and easy. The test is kind of an app for people like me who wants to do more but needs simplified. Rewards programs are better and they’re all in one spot. There’s no 7 rewards banners on one page. Debit card options is the greatest feature and ....',
    date: 'June 4, 2022',
    auth: 'James',
    link: 'https://www.trustpilot.com/reviews/629b0e84d5573095630ac368',
    avatar: null
  },
};