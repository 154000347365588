import React from 'react';
import { useSelector } from 'react-redux';

const useCryptoInterest = ({apyRate}) => {
    let borrowRate = apyRate || useSelector(state => state.app.contentVariables.min_term_rate);
    const loyaltyBenefits = useSelector(state => state?.auth?.loyaltyBenefits);
    const isAuth = useSelector(state => !!state?.auth?.token);

    if (isAuth) {
        borrowRate = parseFloat(borrowRate) - (borrowRate * loyaltyBenefits?.Benefits?.BorrowDecreaseInterestRate / 100);
    }

    return [borrowRate];
};

export default useCryptoInterest;