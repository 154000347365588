import React from 'react';
import FaqsSection from 'src/modules/faqs';

export const BadgesFaqData = [
  {
    question: 'What are MyConstant Badges',
    answer: `
    <p>MyConstant Badges are our way of saying thank you for participating in and promoting the MyConstant platform. Earning badges is easy – you get one the moment you join and for lots of other activities as you invest, borrow, or refer. Every badge comes with a reward, from $1 right up to a generous $100, recognizing the loyalty and hard work you put in referring us to friends, family, and your wider network.</p>
    `
  },
  {
    question: 'Who can earn badges?',
    answer: `
    <p>Anyone – just create a free MyConstant account and you'll earn your first badge and reward (the first of many, we hope!).</p>
    `
  },
  {
    question: 'How can I earn badges and rewards?',
    answer: `
    <p>Almost everything you do on our platform earns you a badge. From verifying your ID (passing KYC) to referring friends, from investing for the first time to reviewing us on Trustpilot, you'll find plenty of opportunities to earn badges and rewards (some badges pay $100!).</p>
    `
  },
  {
    question: 'When will I earn my badges?',
    answer: `
    <p>Most badges are awarded automatically after you qualify, but others might require you to <send us something for approval>. For more information, please check the conditions of the individual badge.</p>
    `
  },
  {
    question: 'How long does the approval process take?',
    answer: `
    <p>Approval should take no longer than two business days, but it might take a little longer if we need more information from you.</p>
    `
  },
  {
    question: 'Do you have any materials to help me earn badges?',
    answer: `
    <p><a href="https://myconstant.com/blog/" target="_blank">https://myconstant.com/blog/</a></p>
    `
  },
  {
    question: 'Where can I check my earned badges and rewards?',
    answer: `
    <p>You'll find a record of all your bages on <a href="/badges">Badges page</a></p>
    `
  },
  {
    question: 'Why haven\'t I received my badge and reward?',
    answer: `
    <p>If you qualify for a badge but haven't been awarded it yet, you might need to send us something for approval. Please check the badge conditions and send us what's necessary, otherwise, drop us a line at hello@myconstant.com and we'll look into this for you.</p>
    `
  },
  {
    question: 'When do I receive badge rewards?',
    answer: `
    <p>You receive your reward immediately after we award your badge.</p>
    `
  },
];

const renderDescription = item => {
  if (React.isValidElement(item) || typeof item === 'string') return item;
  return null;
};

const Faqs = () => {
  return (
    <FaqsSection data={BadgesFaqData.map(item => ({
      ...item,
      answer: <span dangerouslySetInnerHTML={{ __html: renderDescription(item.answer) }} />
    }))}
    />
  );
};

export default Faqs;
