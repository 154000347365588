import React from 'react';
import { useSelector } from 'react-redux';

const useCryptoInterest = ({apyRate}) => {
    let cryptoLendRateStable = apyRate || useSelector(state => state.app.contentVariables.crypto_lend_stablecoin_rate);
    const loyaltyBenefits = useSelector(state => state?.auth?.loyaltyBenefits);
    const isAuth = useSelector(state => !!state?.auth?.token);

    if (isAuth) {
        cryptoLendRateStable = parseFloat(cryptoLendRateStable) + (cryptoLendRateStable * loyaltyBenefits?.Benefits?.CryptolendIncreaseInterestRate / 100);
    }

    return [cryptoLendRateStable];
};

export default useCryptoInterest;