import React from 'react';
import GlossaryLang from 'src/lang/components/GlossaryLang';
import FaqsSection from 'src/modules/faqs';

export const GiftCardFaqData = [
  {
    question: 'How do gift cards work?',
    answer: `
    <p>MyConstant gift cards work like this:</p>
    <ol>
      <li>Choose an amount and retailer from the dropdown box.</li>
      <li>If you have sufficient funds in your balance, click Buy Now.</li>
      <li>You can reveal the gift card code immediately on-screen. We also send the gift card to your registered email address and mobile number via SMS.</li>
      <li>Redeem your gift card online or in-store before the expiry date.</li>
    </ol>
    <p>That's it!</p>
    `,
  },
  {
    question: 'How much cashback will I receive?',
    answer: `
    <p>For most retailers, you’ll get 3% cashback on the face value of the gift card if you buy before Thursday Feb 25th, 2021, at 9am PST. This is credited to your MyConstant balance as cashback immediately at the point of sale. After Jan 25th, the cashback amount might change or be removed.</p>
    `,
  },
  {
    question: 'Is there a limit on the gift card value?',
    answer: `
    <p>Yes, the maximum you can order is $5,000 per month. However, you can extend this limit by $500 each time you refer a friend who signs up and deposits USD.</p>
    `,
  },
  {
    question: 'How do I pay for gift cards?',
    answer: `
    <p>At the moment, you can only pay for gift cards using your available MyConstant balance. If you don’t have enough funds, <a href="/deposit-fiat">you will need to deposit first</a> before ordering your gift card. In the coming weeks, you’ll also be able to create a gift card order that will fulfil automatically when your funds reach us.</p>
    `,
  },
  {
    question: 'Where can I use gift cards?',
    answer: `
    <p>You can use MyConstant gift cards at nearly 50 different retailers, including some of the biggest brands in the US. We’re working closely with our gift card partner to expand our retailer network, offering you some of the best choices of gift cards anywhere online.</p>
    <p><i>Please note: Although you can buy MyConstant gift cards from anywhere in the world, you can only redeem them in the US. We’re working with our partner to extend our retailer network and will let you know when new countries are approved.</i></p>
    `,
  },
  {
    question: 'How do I redeem a gift card?',
    answer: `
    <p>This depends on the retailer. You’ll usually be able to redeem the gift cards online, over the phone, or in-store using the bar code or PIN on the gift card, which is sent to your registered email address. You’ll be given instructions on how to redeem your gift card when you order. If you have questions, drop us an email on <a href="mailto:hello@myconstant.com">hello@myconstant.com</a>.</p>
    `,
  },
  {
    question: 'Can I cancel a gift card and get a refund?',
    answer: `
    <p>No, all gift card sales are final and can’t be transferred or returned. The exception is if the gift card doesn’t work as we have a three-month guarantee that covers faulty gift cards. Please read <strong>the terms and conditions</strong> when you select your gift card for purchase.</p>
    `,
  },
  {
    question: 'Where can I view the terms and conditions of the gift card?',
    answer: `
    <p>When you select a retailer from the dropdown box, you will see a link for the terms and conditions as well as instructions on how to redeem.</p>
    `,
  },
  {
    question: 'What if I lose the gift card?',
    answer: `
    <p>If you lose the gift card or accidentally delete the email it came with, drop us a line on <a href="mailto:hello@myconstant.com">hello@myconstant.com</a> and we’ll help you recover it.</p>
    `,
  },
  {
    question: 'Where can I view gift card orders?',
    answer: `
    <p>Gift card orders will show in your transaction history on your Accounts page. You can also view your orders in your email inbox and mobile SMS history (assuming you haven’t deleted them).</p>
    `,
  },
  {
    question: 'What happens if I don’t spend all the money on the gift card?',
    answer: `
    <p>You must redeem the entire balance in a single transaction or you’ll lose the difference. Gift cards are designed to be spent in one go, so it’s better to have a purchase or occasion in mind before you buy your gift card.</p>
    `,
  },
  {
    question:
      'Are there any fees when buying and using MyConstant gift cards?',
    answer: `
    <p>No, there are no fees.</p>
    `,
  },
  {
    question: 'When do gift cards expire?',
    answer: `
    <p>All gift cards are valid for 12 months from the date you buy them.</p>
    `,
  },
  {
    question: 'Are gift cards available outside of the US?',
    answer: `
    <p>At the moment, gift cards are only available in the US, but we hope to open up to other countries (and retailers) soon.</p>
    `,
  },
  {
    question:
      'Can I exchange a gift card for cash?',
    answer: `
    <p>No, gift cards can only be used to buy goods or services directly from retailers.</p>
    `,
  },
  {
    question: 'How can I trust the gift cards are genuine?',
    answer: `
    <p>All gift cards come with a three-month guarantee: should you be unable to redeem your gift card or lose it within three months of purchase, we can help to replace it with another, free of charge.</p>
    `,
  },
];

const renderDescription = item => {
  if (React.isValidElement(item) || typeof item === 'string') return item;
  return null;
};

const Faqs = () => {
  return (
    <FaqsSection data={GiftCardFaqData.map(item => ({
      ...item,
      answer: (
        <GlossaryLang displayHtml={renderDescription(item.answer)}/>
      ),
    }))}
    />
  );
};

export default Faqs;
