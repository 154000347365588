import React from 'react';
import FaqsSection from 'src/modules/faqs';

export const InvestFaqData = [
  {
    question: 'How does MyConstant investing work?',
    answer: `
    <p>When you invest with us, you first send your funds (either fiat like USD or stablecoins) to our custodial partner Prime Trust, an accredited US financial institution.</p>
    <p>Prime Trust then converts your funds to a relevant stablecoin which we then lend on your behalf to collateralized borrowers, liquidity pools, and decentralized exchanges in return for interest.</p>
    <p>When you’re ready to withdraw, Prime Trust converts your invested stablecoins back to the relevant currency of your withdrawal request. There is no charge or fees for fiat (USD etc) withdrawals. You’ll keep the interest you earn up to the moment you withdraw your funds.</p>
    <p>You can choose our instant access account or invest for a fixed term. Our instant access account has a 24-hour term that’s automatically extended every 24 hours unless you choose to disable the product or withdraw your funds.</p>
    <p>All account statements for MyConstant investment products are stated in USD equivalent terms at all times.</p>
    `,
  },
  {
    question: 'How do I invest?',
    answer: `
    <p>First, create your free MyConstant account.</p>
    <p>Then, decide whether you need instant access to your money or if you can afford to put it away for 30, 60, or 180 days. (Remember: the longer you invest, the more you earn!)</p>
    <p>Once you’ve chosen an amount and term, enter it above and then click “Invest now”. This creates your investment order. The next step is to send us the funds to match your investment order.</p>
    <p>If you’re in the US, link your bank account for easy ACH transfers with Plaid.</p>
    <p>If you’re outside of the US, the only option is to send a wire.</p>
    <p>Instructions for both will be on-screen when you create your deposit order and also in the investment confirmation email we send you.</p>
    <p>Then, as soon as your funds reach us, you’ll start earning interest.</p>
    <p>If you choose an instant access term, you can withdraw your money and profit at any time for free. Otherwise, you’ll need to wait until the end of your fixed term before you can access your funds or reinvest (if pushed, you can also end your fixed term early on our secondary market).</p>
    <p><a href="https://myconstant.com/blog/how-to-invest-with-constant-in-3-simple-steps" className="underline" target="_blank">How to invest in 3 simple steps.</a></p>
    `,
  },
  // {
  //   question: 'How long will it take to find a match?',
  //   answer: `
  //   <p>You match the moment we receive your funds.</p>
  //   `,
  // },
  {
    question: 'How long until I start earning interest?',
    answer: `
    <p>You start earning interest the moment we receive your funds.</p>
    `,
  },
  {
    question: 'Are there any limits?',
    answer: `
    <p>The minimum investment is $10 for instant access investments and $50 for a fixed-term investment. There is no upper limit on the amount you can invest.</p>
    `,
  },
  {
    question: 'How do you protect my investment?',
    answer: `
    <p>We’ve taken important steps to safeguard not just your investment, but your returns, too:</p>
    <ol>
      <li>Where the collateral is cryptocurrency, borrowers must put up to 200% of the loan amount in collateral, either as a cryptocurrency or a portfolio of cryptocurrencies.</li>
      <li>We also include a liquidation threshold for cryptocurrencies to insure against a fall in the collateral’s value. If the value falls to 110% of your principal and earned interest, it’s sold to repay you.</li>
      <li>We only accept vetted, quality cryptocurrencies and cap our exposure to these markets by daily trading volume. This ensures we can sell the collateral should the market dip.</li>
    </ol>
    <p>These protective measures help ensure you get the returns promised. However, all investment involves risk, and despite our best efforts, we can’t guarantee the return of your principal and profit. Therefore please invest wisely.</p>
    <p><a href="https://myconstant.com/blog/how-we-protect-your-money-and-collateral" className="underline" target="_blank">Read more about how we protect you.</a></p>
    `,
  },
  {
    question: 'What happens if a borrower repays early?',
    answer: `
    <p>If a borrower repays before 75% of the loan term is complete, they’ll pay you earned interest to date plus 50% of the interest due on the remainder of the term.</p>
    <p>If a borrower repays after 75% of the loan term is complete, they’ll pay you 100% of the interest due on the loan.</p>
    `,
  },
  {
    question: 'What happens if you have to sell borrower collateral to repay me?',
    answer: `
    <p>If a borrower can’t repay, or their collateral falls to 110% of your principal and earned profit, we’ll sell the collateral to repay you. In either case, you’ll get your principal back. You’ll also get earned interest to date if the collateral is sold mid-term, or 100% of the interest due if the collateral is sold after the term ends.</p>
    `,
  },
  // {
  //   question: 'What is a corporate bond?',
  //   answer: `
  //   <p>A corporate bond is a debt obligation. Companies sell bonds in return for cash to finance new assets, premises, expansion and so on. The bond entitles the buyer (the lender) to repayment of the value of the bond, either monthly or when the bond matures, which includes interest.</p>
  //   `,
  // },
  // {
  //   question: 'How does bond collateral work?',
  //   answer: `
  //   <p>We accept corporate and emerging market bonds as part of our collateral mix.</p>
  //   <p>This means a borrower can put up a bond they own as collateral for a loan through our platform.</p>
  //   <p>The face value or price of the bond must be at least 100% of the loan amount. At the end of the term, the borrower repays or we take ownership of the bond and collect payment when it matures to return your principal and earned profit.</p>
  //   <p>Like cryptocurrencies, bonds are liquid assets. However, they’re often issued for large sums of money. A single borrower might post a bond worth tens or even hundreds of thousands of dollars, for example. In which case, it might take several investors to match the order. As a result, we only accept bonds with a maturity of six months or less.</p>
  //   `,
  // },
  // {
  //   question: 'How do the risks differ between bond and cryptocurrency collateral?',
  //   answer: `
  //   <p>Corporate bonds and cryptocurrencies are very different assets.</p>
  //   <p>Cryptocurrencies tend to be liquid, so are easy to sell. But they’re also prone to sudden and violent market swings, which is why we overcollateralize all loans backed by cryptocurrencies.</p>
  //   <p>Bonds, on the other hand, are less volatile, so borrowers needn’t always overcollateralize. While the face value of the bond (the amount due to be repaid at maturity) never changes, its trading price might, since bank interest rates affect the yield of a bond over time.</p>
  //   <p>Banks, however, generally review interest rates every six months. Since we only accept bonds of six months or less, we should avoid any unexpected price changes in bond collateral, which means we never expect to sell a bond mid-term.</p>
  //   <p>The primary risk of accepting bonds as collateral, then, is whether the company that issued the bond repays the face value amount at maturity. Many factors can affect their ability to repay, and rather than list them here, please remember that all businesses are susceptible to risk and loss, whether from competition, loss of employees, natural disasters, law suits, and lots of others.</p>
  //   <p>Bond collateral, like cryptocurrency collateral, doesn’t guarantee your investment. But at MyConstant, we believe in diversification as the best protection against losses. With the introduction of corporate bonds as collateral, our collateral mix is richer and more diverse, which mitigates the risk of default than if we chose either on their own.</p>
  //   `,
  // },
  // {
  //   question: 'Can I choose to back my investment with a bond or cryptocurrency?',
  //   answer: `
  //   <p>No, and for good reason. When your investment is backed by multiple collateral types (in this case, bonds and cryptocurrencies) you benefit from diversification. To back your investment with one or the other alone creates a single point of failure. We now accept bonds as collateral to increase the diversity of our collateral mix so you’re not dependent on any one asset class.</p>
  //   `,
  // },
  {
    question: 'Can I end my investment term early?',
    answer: `
    <p>Yes. To end your term early, you can sell your investment to another investor from your Accounts page. Once sold, you’ll get your principal back and 2% APR on elapsed term time. Selling relies on finding a buyer willing to accept your rate and term so might take around 24 hours. You’ll also lose any earned membership points on your sold investment.</p>
    `,
  },
];

const renderDescription = item => {
  if (React.isValidElement(item) || typeof item === 'string') return item;
  return null;
};

const TopFaqs = () => {
  return (
    <FaqsSection data={InvestFaqData.map(item => ({
      ...item,
      answer: (
        <span
          dangerouslySetInnerHTML={{
            __html: renderDescription(item.answer),
          }}
        />
      ),
    }))}
    />
  );
};

export default TopFaqs;
