import React from 'react';
import { connect } from 'react-redux';
import FaqsSection from 'src/modules/faqs';
import { DEFAULT_FLEX_RATE } from 'src/resources/constants/app';
import { formatMoneyByLocale } from 'src/utils/format/currency';
import useInvestInterest from 'src/hooks/useInvestInterest';

export const SignUpFaqData = ({ kycTrialAmount = '4,000', kycTrialPeriod = 15, maxTermRate}) => ([
  {
    question: 'What is MyConstant?',
    answer: `<p>MyConstant is a multi-market P2P investment platform that pays up to 11% APR. With us, you can invest in people and businesses around the world, without fees or limits, with all lending backed by collateral or loan originator’s buy-back guarantee. We have a range of easy and accessible investment products for every risk appetite and goal, and provide you with the learning resources to achieve those goals, fast.</p>`,
  },
  {
    question: `What is the $${kycTrialAmount} trial bonus?`,
    answer: `
      <p>The $${kycTrialAmount} trial bonus is a way to experience investing on our platform without committing any of your own money. Instead, we credit your Instant-access investing account with a $${kycTrialAmount} bonus that you can watch grow at ${DEFAULT_FLEX_RATE}% APY, compounded and paid every second. After ${kycTrialPeriod}-days we reclaim the bonus but you keep the interest. You can withdraw only after you've made a USD deposit.</p>
      <p class="font-italic">*Note: to get the $${kycTrialAmount} trial bonus, you must submit your KYC documents within 30-days after signing up. Also, you can only withdraw the interest after you've made a USD deposit.</p>
    `,
  },
  {
    question: 'Why are you offering this bonus?',
    answer: `<p>We understand that investing can be a little daunting at first – especially if you’ve never done it before. The bonus therefore gives you a taste for investing with us and will hopefully inspire you to continue investing with us for even bigger returns.</p>`,
  },
  {
    question: 'How do I get the bonus?',
    answer: `<p>To qualify for the bonus, you must be a US citizen. Then, you simply sign up for a free MyConstant account, verify your ID (KYC), and your $${kycTrialAmount} bonus will be paid into your account immediately. You won’t be able to withdraw the bonus, and it’ll be debited from your account after ${kycTrialPeriod} days, but you keep all the interest.</p>`,
  },
  {
    question: 'How do I verify my ID?',
    answer: `
    <p>We have to verify your ID to comply with US Anti-Money Laundering (AML) and Know Your Customer (KYC) regulations.</p>
    <p>Once you’ve created an account, you’ll be asked to submit address and ID proof, so we can verify your ID and approve your account.</p>
    <p>KYC verification is usually very fast, assuming you submit all relevant details and they’re clear, legible, and valid.</p>
    <p>Here’s a tip sheet: <a href="https://myconstant.com/blog/how-to-verify-your-id-on-constant-kyc/" class="underline" target="_blank">How to verify your ID on MyConstant</a>.</p>
    `,
  },
  {
    question: `Can I withdraw the $${kycTrialAmount}?`,
    answer: `<p>No, the $${kycTrialAmount} is locked into your Instant-access investing account for the ${kycTrialPeriod} days. After ${kycTrialPeriod} days, we take back the $${kycTrialAmount} but you keep the interest.</p>`,
  },
  {
    question: 'What happens after the trial?',
    answer: `
     <p>Once we’ve taken back the $${kycTrialAmount}, you keep the interest. It’s up to you what you’d like to do next. You can keep your interest in Instant-access investing earning ${DEFAULT_FLEX_RATE}% APY or you can add some of your own money and reinvest in a crypto-backed loan for ${maxTermRate}% APR (minimum investment is $50). You can also just withdraw it to your bank account after you've made a USD deposit. The choice is yours!</p>
     <p>
        <strong>Important note:</strong> You must deposit, withdraw, or invest within 60 days of receiving your free interest otherwise it will expire.
     </p>
    `,
  },
]);

const renderItem = item => {
  if (React.isValidElement(item) || typeof item === 'string') return item;
  return null;
};

const TopFaqs = ({ kycTrialAmount, kycTrialPeriod }) => {
  const [maxTermRate] = useInvestInterest({});
  return (
    <FaqsSection
      data={SignUpFaqData({ kycTrialAmount: formatMoneyByLocale(kycTrialAmount), kycTrialPeriod, maxTermRate}).map(item => ({
        question: <span name='title' dangerouslySetInnerHTML={{ __html: renderItem(item.question) }}/>,
        answer: <span name='title' dangerouslySetInnerHTML={{ __html: renderItem(item.answer) }}/>,
      }))}
    />
  );
};

const mapState = state => ({
  kycTrialAmount: state.app.USER_KYC_US_TRIAL_AMOUNT,
  kycTrialPeriod: state.app.USER_KYC_US_TRIAL_PERIOD,
});

export default connect(mapState, null)(TopFaqs);
