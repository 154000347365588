import React from 'react';
import FaqsSection from 'src/modules/faqs';
import GlossaryLang from 'src/lang/components/GlossaryLang';

export const MembershipFaqData = [
  {
    question: 'Do the minimum investment/borrow amounts apply to single loans only?',
    answer: '          <p>          No, you can invest or borrow multiple times within the 180 days to become a Gold, Platinum, or Diamond member.          </p>          '
  },
  {
    question: 'Do outstanding loans and investments qualify for points?',
    answer: '          <p>          Yes. You can meet the minimum threshold for membership on active loans and investments that are yet to mature.          </p>          '
  },
  {
    question: 'Can I combine loans and investments to reach the membership threshold?',
    answer: '          <p>          No. The membership thresholds apply separately to loans and investments. For example, you must invest $25,000 or borrow $25,000 to qualify for Gold membership.          </p>          '
  },
  {
    question: 'How long do membership rewards last?',
    answer: '          <p>          The $50 cashback rewards and STAR bonus are <strong>one-off rewards only</strong>. The other benefits last as long as your points do. You earn a point for every dollar you invest or borrow and each point expires after 180 days. So, if you make a single investment of $25,000, for example, your points will last 180 days and so will the benefits.          </p>          <p>          If you invest several times to reach the minimum, your rewards will last 180 days from the first qualifying point you earned. For example, if you invest $10,000 and then $15,000 three months later, your benefits will continue until your first batch of points expire.           </p>          '
  },
  {
    question: 'Can I extend membership rewards?',
    answer: '          <p>          Yes! When your points expire, simply invest or borrow again to top them up. This way, you can keep your membership benefits for as long as you want them.           </p>          '
  },
  {
    question: 'Do I need to contact you when I’ve reached the minimum amount?',
    answer: '          <p>          No, you don’t need to do anything. We’ll track your investment and loan volume on a rolling six-month basis and when you meet the minimum threshold, we’ll immediately upgrade you to Gold, Platinum, or Diamond status.           </p>          '
  },
  {
    question: 'Why is there an expiration date on membership points?',
    answer: '          <p>          While there is an expiration date on points there is no expiration date on the Membership Rewards program. This is our way of saying thank you to our most loyal and active customers, so we want to run the program for as long as possible. The expiration date allows us to tie membership strictly to active, regular users of our platform.           </p>          '
  },
  {
    question: 'Can you qualify for each level multiple times?',
    answer: '          <p>          Yes, you can. However, the $150 and STAR bonuses can only be claimed once during the lifetime of your account. You can maintain the other benefits indefinitely.           </p>          '
  },
  {
    question: 'Can I earn all the benefits at once?',
    answer: '          <p>          Interest from referrals is exclusive to each level of membership. The instant credit reward can each be earned only one time per each level. For example, if you invest $100,000, you’ll get $100 + 1 STAR + 20% of earned interest from referred friends’ Instant-access investing accounts (until your membership points expire).           </p>          '
  },
  {
    question: 'How do I redeem STAR?',
    answer: '          <p>          By going to your <a href="/accounts" class="underline bold">Accounts</a> page you can redeem STAR against any active investment or loan. 1 STAR = +/- 1% interest (depending on if you’re a lender or borrower). The bonus or discount is applied from the date of redemption until the loan or investment matures. STAR tokens don’t expire, so you can save them up and redeem them in one go if you want.           </p>          '
  },
  {
    question: 'What happens when my points expire?',
    answer: '          <p>          You can top points up to maintain your membership benefits. Your Membership Rewards page shows when your points expire and you’ll also receive an email reminder from us too. If you allow your points to expire, you will lose your membership and benefits, but can always re-qualify by earning enough points. Please note the $150 and STAR bonuses are one-time benefits — you can only earn them once per level.           </p>          '
  },
  {
    question: 'Will my referral interest change when my points expire?',
    answer: '          <p>          As stated above, if you allow your points to expire, you will lose your membership and benefits. This includes bonus referral interest rates. However, all special interest rates from friends you refer will be upgraded upon achieving membership.           </p>          '
  },
  {
    question: 'Do I have to complete 3 Trustpilot reviews to get all $150?',
    answer: '          <p>          When you reach a new membership level you will be prompted to leave a review on our Trustpilot. Once you click the link to our Trustpilot on our membership page and enter your username your reward will be automatically credited to your account. If you have done this once you never have to do it again. Thanks for spreading the word about our platform :).           </p>          '
  },
  {
    question: 'I am already a member. Can I claim an additional $50 or STAR reward for my current level?',
    answer: '          <p>          If you’re already participating in our membership program then, first of all, thank you! But since you’ve already received a reward for your current level, you must move up a level to receive new rewards.           </p>           <p>          <strong>For example:</strong> if you are already a Gold member you won’t be able to earn $50 cashback for Gold again but can gain $50 rewards if you upgrade to Platinum or Diamond. If you’re already a Diamond member you’ll be unable to receive further one-time rewards from any level as you already have earned them.           </p>           <p>          You will, however, be able to receive all extra interest and rewards on your new referrals based on your current level as well as its new withdrawal/deposit options.           </p>          '
  }
];

const renderDescription = item => {
  if (React.isValidElement(item) || typeof item === 'string') return item;
  return null;
};

const TopFaqs = () => {
  return (
    <FaqsSection data={MembershipFaqData.map(item => ({
      ...item,
      answer: (
        <GlossaryLang displayHtml={renderDescription(item.answer)} />
      ),
    }))}
    />
  );
};

export default TopFaqs;
