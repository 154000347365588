import React, { useState } from 'react';
import FaqsSection from 'src/modules/faqs';
import ga, { events } from 'src/services/ga';
import localStore from 'src/services/localStore';
import { USER } from 'src/resources/constants/user';

export const BorrowFaqData = [
  {
    question: 'What’s the maximum I can borrow?',
    answer: `
    <p>You can borrow up to $50,000 in one order. To borrow more, you’d need to split your loan into separate orders. We limit the amount you can borrow in a single transaction to limit the risk of liquidation affecting the sale price, and subsequently, our ability to repay investors.</p>
    `,
  },
  {
    question: 'How long will it take to get my loan?',
    answer: `
    <p>As soon as we receive your collateral, we’ll deposit your loan into your MyConstant account. You can withdraw your loan as fiat to your bank account or as a stablecoin to your wallet address. All USD withdrawals are free. Stablecoin withdrawal fees are <a href="/getPrices" class="underline">here</a>.</p>
    <p><a href="https://myconstant.com/blog/how-to-borrow-against-crypto-4-simple-steps" className="underline" target="_blank">How to borrow in 4 simple steps.</a></p>
    `,
  },
  {
    question: 'Do you check my credit score?',
    answer: `
    <p>As we only offer secured loans backed by collateral, there is no need for credit scoring. All you need is adequate collateral to secure your loan. To see how much collateral you need to secure your loan, simply enter the amount you’d like to borrow. The calculator will work out the amount for you.</p>
    <p><a href="https://myconstant.com/blog/how-to-borrow-against-crypto-4-simple-steps" className="underline" target="_blank">How to borrow in 4 simple steps.</a></p>
    `,
  },
  {
    question: 'How do you protect my collateral?',
    answer: `
    <p>Depending on your collateral, it’s either held safely in Prime Trust custody, an Ethereum smart contract, or a password-protected web wallet hosted on a dedicated server (to which only our CEO and CTO have access). When you repay, your collateral is immediately transferred back to you.</p>
    <p><a href="https://myconstant.com/blog/how-we-protect-your-money-and-collateral" className="underline" target="_blank">How we protect your collateral.</a></p>
    `,
  },
  {
    question: 'What happens if I don’t repay the loan?',
    answer: `
    <p>If you don’t repay the loan, your collateral will be sold to repay your investor. You get to keep the loan. That’s it.</p>
    `,
  },
  {
    question: 'Can I repay early?',
    answer: `
    <p>You sure can! You can repay anytime before the term is up — no fees or penalties incurred. You only pay interest on the days you borrow.</p>
    `,
  },
  {
    question: 'What happens if my crypto collateral falls in value?',
    answer: `
    <p>We will notify you three times at 5% intervals before your collateral falls to 110% of the loan value + interest to date. At that point, we’ll create a sell order to repay the investor. You keep the loan, but you lose your collateral.</p>
    <p>Borrowers will receive notifications at 125%, 120% and 115% of the investor’s principal plus earned interest. You can choose to repay early to avoid liquidation or top up more collateral. In the event of a flash crash, collateral will be automatically liquidated at 110%.</p>        <p>If you’re borrowing against stablecoins, you will be notified at 115%, 110%, and finally 105%. Once your collateral falls to 100% of the loan amount it will be sold to repay the investor.</p>        <p><a href="https://myconstant.com/blog/extra-protection-for-your-collateral-auto-top-up" className="underline" target="_blank">How to avoid liquidation with auto top-up.</a></p>
    `,
  },
  {
    question: 'Can I recall excess if the value of my collateral increases?',
    answer: `
    <p>Yes. If the value of your collateral rises during your loan term, you can withdraw the difference from your Accounts page.</p>
    <p><a href="https://myconstant.com/blog/how-to-deposit-top-up-recall-crypto-constant" className="underline" target="_blank">How to deposit, top-up, and recall excess collateral.</a></p>
    `,
  },
  {
    question: 'What is the LTV (Loan-to-Value) Ratio? Is it always the same?',
    answer: `
    <p>The LTV ratio varies according to the trading activity and liquidity of your chosen collateral. Most supported collateral has an LTV ratio of 66% for 9-month loans or shorter. That means we require 150% of the loan value in collateral.</p>
    `,
  },
  {
    question: 'How do I repay my loan?',
    answer: `
    <p>We’ll deduct your repayment directly from your MyConstant account balance. Adding funds is easy. You can do one or a combination of the following:</p>
    <ol>
      <li>Transfer USD to one of our US bank accounts.</li>
      <li>Send any one of our supported stablecoins: USDT/USDC/TUSD/GUSD/PAX/USDS.</li>
      <li>Repay the equivalent USD value using your collateral<sup>*</sup>.</li>
    </ol>
    <p class="font-italic"><strong><sup>*</sup>Please note:</strong> We might restrict your ability to repay using collateral depending on the collateral type, loan amount, and market conditions. This is to protect our ability to sell cryptocurrency collateral at prices that adequately cover investor commitment.</p>
    <p>Your balance will update when funds have been received in your MyConstant account.</p>
    `,
  },
  {
    question: 'What happens if I repay late?',
    answer: `
    <p>The grace period for receiving payment is 3 days after your term ends. On the 2nd and 3rd day, you will incur a late fee of 10% of the total interest due.</p>
    `,
  },
  {
    question: 'Is it still possible to take out a single-collateral loan?',
    answer: `
    <p>Yes, after you take out a loan you can click on the Isolate button in the loan menu on your accounts page to switch to a single-collateral loan. To switch you must meet the 110% minimum collateral value requirement for the crypto. However, you cannot collateralize with more crypto than your collateral rate allows (more on that below).</p>
    `,
  },
  {
    question: 'Is repayment any different with multi-collateral credit lines?',
    answer: `
    <p>Nope! You must still repay each loan individually. However, crypto selected for collateral repayments will still follow a 24-hour trading volume priority based on what you have available.</p>
    `,
  },
  {
    question: 'I can’t find where to choose multiple collaterals for my borrow order',
    answer: `
    <p>If you take out a crypto-backed loan or buy new cryptos using Crypto Credit, you’ll only see the option to use one collateral. To borrow with multiple collaterals you must start on <a href="https://www.myconstant.com/pro-lending" target="_blank" class="underline">our pro page.</a></p>
    `,
  },
  {
    question: 'Why do you prioritize collaterals based on 24-hour exchange volume?',
    answer: `
    <p>Loan security is our top priority on MyConstant. In the unfortunate case of liquidation, we always want to make sure we can sell collateral as fast as possible to cover the loan value.</p>
    `,
  },
  {
    question: 'Will auto top-up add collateral to my loan based on 24-hour exchange volume?',
    answer: `
    <p>Yes.</p>
    `,
  },
  {
    question: 'Can I choose to auto top-up some loans and manually top-up others?',
    answer: `
    <p>No. You can only toggle auto top-up on or off for multi-collateral loans. If you isolate your loan you will need to manually top-up.</p>
    `,
  },
  {
    question: 'Will I still receive notifications to repay individual multi-collateral loans on schedule?',
    answer: `
    <p>Yes.</p>
    `,
  },
  {
    question: 'Do you have a feature for paying all loans in one term period at the same time?',
    answer: `
    <p>Right now you still must pay off all of your loans individually.</p>
    `,
  },
  {
    question: 'If I have multiple active loans, which collaterals do I get back when I repay my earliest loans?',
    answer: `
    <p>If you still have active loans after repaying, your collateral won’t be released back to your available balance. Instead, it will go into your collateral balance for multi-collateral. If you want to remove collateral from this balance, you’ll need to recall excess from the overview screen and choose the crypto type and amount you want.</p>
    `,
  },
  {
    question: 'I can’t recall excess on my crypto even though my collateral value is over 110%.',
    answer: `
    <p>Collateral value is only used to determine loan liquidation. It is calculated by:</p>
    <p class="font-italic">(number of coins * value)+”repeat for each coin”/(loan value + up-to-date interest + matching fee)</p>
    <p>We calculate how much collateral you withdraw through recall excess based on the collateral rate. For more information on the collateral rate see below.</p>
    `,
  },
  {
    question: 'What is the collateral rate and why is it different from the collateral value?',
    answer: `
    <p>Collateral value only takes into account the monetary value of your collateral in your collateral balance compared to your loan amount. We base liquidation off collateral value. Your collateral rate is a more in-depth number that takes into account the LTV ratios required for different cryptocurrencies. We use this number to determine how much you can withdraw with recall excess as well as the maximum amount of crypto you can place in an Isolated loan.</p>
    <p>You can find your collateral rate through this equation:</p>
    <p class="font-italic">x = (number of coins)*(coin value)*(LTV)+“repeat for each coin”<br />Collateral rate = (x/(loan value at first + up-to-date interest + matching fees))*100</p>
    `,
  },
];

const renderDescription = item => {
  if (React.isValidElement(item) || typeof item === 'string') return item;
  return null;
};

const TopFaqs = () => {
  const [isOpened, setIsOpened] = useState({});

  const onToggle = index => {
    if (isOpened[index]) return;
    const actions = {
      0: events.action.borrow_FAQ_maxloan,
      1: events.action.borrow_FAQ_loanreceivingtime,
      2: events.action.borrow_FAQ_creditscorecheck,
      3: events.action.borrow_FAQ_protectcollateral,
      4: events.action.borrow_FAQ_norepayloan,
      5: events.action.borrow_FAQ_repayearly,
      6: events.action.borrow_FAQ_cryptopricedrop,
      7: events.action.borrow_FAQ_recallexcess,
      8: events.action.borrow_FAQ_LTVratio,
      9: events.action.borrow_FAQ_howtorepay,
      10: events.action.borrow_FAQ_repaylate,
      11: events.action.borrow_FAQ_isolatedloan,
      12: events.action.borrow_FAQ_multicollateralrepayment,
      13: events.action.borrow_FAQ_wheretoborrowmulticollateral,
      14: events.action.borrow_FAQ_why24hrsexvolume,
      15: events.action.borrow_FAQ_loanautotopup,
      16: events.action.borrow_FAQ_loantopuptoggle,
      17: events.action.borrow_FAQ_repaynotification,
      18: events.action.borrow_FAQ_payallloan,
      19: events.action.borrow_FAQ_repayandrecallexcesss,
      20: events.action.borrow_FAQ_cannotrecallexcess,
      21: events.action.borrow_FAQ_whatcollateralrate,
    };
    const email = JSON.parse(localStore.get(USER.CURRENT_PROFILE))?.email || '';
    ga(events.category.borrow, actions[index], email);
    setIsOpened({ ...isOpened, [index]: true });
  };

  return (
    <FaqsSection
      onToggleItem={onToggle}
      data={BorrowFaqData.map(item => ({
        ...item,
        answer: (
          <span
            dangerouslySetInnerHTML={{
              __html: renderDescription(item.answer),
            }}
          />
        ),
      }))}
    />
  );
};

export default TopFaqs;
