import React from 'react';
import { useSelector } from 'react-redux';

import GlossaryLang from 'src/lang/components/GlossaryLang';
import FaqsSection from 'src/modules/faqs';
import { formatCollateralDisplayShorten } from 'src/components/exchangeMethods/loan/utils';
import useCryptoInterest from 'src/hooks/useCryptoInterest';
import { formatMoneyByLocale } from 'src/utils/format/currency';
import { calculateInterestMembership } from 'src/utils/common';

const getListCryptoLend = (flexCollateralsList) => {
  return (flexCollateralsList?.map(c => {
    if (c.symbol === 'USDT' || c.symbol === 'USDC') {
      return `<strong>${c.symbol}</strong>`;
    }
    return c.symbol;
  }).join(', '));
};

const CryptoLendTierDescription = (flexCollateralsList) => {
  let currencyList = [...flexCollateralsList];
  const isAuth = useSelector(state => !!state.auth.token);
  const loyaltyBenefits = useSelector(state => state?.auth?.loyaltyBenefits);

  //Sort USDT, USDC to top
  const stableCoins = ['ETH', 'BTC', 'BNB', 'USDT', 'USDC'];

  stableCoins.forEach(symbol => {
    const index = currencyList.findIndex(i => i.symbol === symbol);
    if (index >= 0) {
      const item = currencyList[index];
      currencyList.splice(index, 1);
      currencyList.unshift(item);
    }
  });

  let currentTier = null;

  return `
  <ul>
    ${
      currencyList?.map(c => {
        const isStableCoin = c.symbol === 'USDT' || c.symbol === 'USDC';
        const selectedPayCollateral = c.collateralFlexs[0];
        const apyRateMembership = calculateInterestMembership(selectedPayCollateral?.apyRate, loyaltyBenefits?.Benefits?.CryptolendIncreaseInterestRate || 0);
        let rate = '';
        
        if(selectedPayCollateral?.tiers?.length > 0) {
          if(selectedPayCollateral?.isTierMembership) {
            if(isAuth) {
              currentTier = selectedPayCollateral?.tiers[loyaltyBenefits?.Benefits?.Level];
            } else {
              currentTier = selectedPayCollateral?.tiers[0];
            }
          } else {
            currentTier = selectedPayCollateral?.tiers[0];
          }
          rate = `${calculateInterestMembership(selectedPayCollateral?.apyRate, loyaltyBenefits?.Benefits?.CryptolendIncreaseInterestRate || 0)}% APY on $${formatMoneyByLocale(currentTier?.fromBalance)} (${calculateInterestMembership(currentTier?.apyRate, loyaltyBenefits?.Benefits?.CryptolendIncreaseInterestRate || 0)}% APY on any amount over)`;
        } else if (c.flexMaxAmount === 0) {
          rate = `${apyRateMembership}% Unlimited`;
        } else if (c.flexMaxAmount > 0) {
          rate = `0 - ${formatCollateralDisplayShorten(c.flexMaxAmount)} (${apyRateMembership}%), > ${formatCollateralDisplayShorten(c.flexMaxAmount)} (0%)`;
        }
        
        return `<li>${isStableCoin ? '<strong>' : ''}${c.symbol}: ${rate}${isStableCoin ? '</strong>' : ''}</li>`;
      }).join('')
    }
    </ul>
  `;
};

export const CryptoLendFaqData = ({ cryptoLendRate = 4, prvCryptoLendRate = 7, flexCollateralsList = [], cryptoLendRateStable }) => [
  {
    question: 'How is interest earned?',
    answer: `
    <p>Your crypto funds liquidity in several decentralized exchanges (DEXs) and swapping platforms. When people trade or swap these cryptocurrencies, you earn a cut of the trading fees. Incognito, our primary exchange partner, has agreed to pay ${prvCryptoLendRate}% APR in its native privacy token, PRV. You can also earn up to ${cryptoLendRateStable}% APY for your USDT, USDC deposit. You can also choose to receive interest in your invested crypto (${getListCryptoLend(flexCollateralsList)}) but at a lower rate of ${cryptoLendRate}% APY.</p>
    `
  },
  {
    question: 'Are there any fees?',
    answer: `
    <p>We don’t charge any fees, but you might pay network or gas fees charged by your wallet or exchange.</p>
    `
  },
  {
    question: 'Which tokens can I lend?',
    answer: `
    <p>You can lend ${getListCryptoLend(flexCollateralsList)}.</p>
    `
  },
  {
    question: 'Which DEX and swapping platforms are you working with?',
    answer: `
    <p>Incognito is one of our favorite blockchain initiatives and will be our primary partner for Crypto Lend. We may also lend to a small number of other exchanges.</p>
    `
  },
  {
    question: 'Is this a type of staking?',
    answer: `
    <p>No, you’re providing liquidity to a DEX. There is no lock-up period and you can withdraw at any time, instantly, and without fees.</p>
    `
  },
  {
    question: 'What is the lock-up period?',
    answer: `
    <p>There is no lock-up period.</p>
    `
  },
  {
    question: 'What is PRV?',
    answer: `
    <p>PRV is the native token of privacy network, Incognito. It enables you to digitally transact crypto in private and is readily exchanged for other cryptocurrencies through Incognito’s pDEX (decentralized exchange). We negotiated a special interest rate in PRV that helps you take advantage of private transactions over the Incognito network. To learn more about PRV, <a class="underline" href="https://incognito.org/" target="_blank">visit the Incognito community</a>.</p>
    `,
  },
  {
    question: 'Why is the interest rate higher for PRV?',
    answer: `
    <p>Incognito is a valued exchange partner and we believe in their privacy mission. We negotiated a higher cut of trading fees to incentivize you to protect your financial data. With interest in PRV, you can trade a large selection of cryptocurrencies over Incognito’s pDEX without leaving a trail of financial breadcrumbs.</p>
    `,
  },
  {
    question: 'How do I get the higher rate in PRV?',
    answer: `
    <p>You can choose how to receive interest from your Accounts settings here: <a class="underline" href="/me/information">https://www.myconstant.com/me/information</a></p>
    `,
  },
  {
    question: 'When will I receive interest?',
    answer: `
    <p>If you choose to receive your interest in PRV, your interest is paid every hour (but not compounded). If you receive interest in your invested crypto, your interest is compounded and paid every second.</p>
    `
  },
  {
    question: 'How fast are withdrawals?',
    answer: `
    <p>Withdrawals under $10,000 equivalent value can take a few minutes. Withdrawals for larger amounts involve a manual review but will take no longer than 1 business day.</p>
    `
  },
  {
    question: 'Are there any limits on investments?',
    answer: `
    <p>Yes, there are investment caps on certain tokens depending on your membership tier (Base/Gold/Platinum/Diamond). See the rates and caps below:</p>
    ${CryptoLendTierDescription(flexCollateralsList)}
    <p>Since the interest rates and cap on each token can change based on your membership tier, it’s important to <a href="https://www.myconstant.com/membership" class="underline">check out the membership page for more details</a>.</p>
    `
  },
  {
    question: 'Can I invest supported cryptocurrencies from my coin balance?',
    answer: `
    <p>If you have our supported coins in your coin balance and they’re not being used as collateral, they’ll automatically earn ${cryptoLendRate}% APY through Crypto Lend. You can also receive ${prvCryptoLendRate}% APR if you earn interest in PRV – just change your settings here: <a href="/me/information" class="underline">https://www.myconstant.com/me/information</a></p>
    <p>If you don’t want to earn interest on the available cryptocurrencies in your coin balance, please withdraw them to a wallet of your choosing.</p>
    `
  },
  {
    question: 'How is interest calculated and paid?',
    answer: `
    <p>Interest paid in our supported tokens is compounded and paid every second. Interest in PRV is calculated at a fixed rate (APR) and paid hourly.</p>
    `
  },
  {
    question: 'What is the MyConstant Guarantee?',
    answer: `
    <p>Crypto Lend pays you interest for lending your cryptocurrencies to third parties whom we vet and approve. To offer you some reassurance, we have set a cap on our exposure to Crypto Lend investments beyond which we won't accept new investments. This is the MyConstant Guarantee: an internal measure of the maximum we could reimburse should your cryptocurrency be lost or stolen while in the custody of one of our partners. It is not an insurance and doesn't cover every possible loss, so your cryptocurrency is still at risk.</p>
    `
  },
  {
    question: 'Does the MyConstant Guarantee protect me from all losses?',
    answer: `
    <p id="#last-faq">No. It is designed to reimburse you in the event your cryptocurrency suffers a loss or theft while in the custody of a partner. This limit is based on an internal assessment of our company financials and is subject to change. Any changes in the MyConstant Guarantee are reflected in the Crypto Lend investment limit. The MyConstant Guarantee is intended to mitigate a portion of risk and offer some reassurance while lending cryptocurrency to decentralized exchanges but is not an insurance. Like all investments, investing in Crypto Lend involves risk. Please use caution and diversify your investments.</p>
    `
  },
  // {
  //   question: 'Is 12% APY with USDT investment option real?',
  //   answer: `
  //   <p>Yes, it is. You can lend your USDT, USDC and earn up to 12%APY or 12%APR if you withdraw in PRV. There are two tiers: Invest 0-20,000 USDT or 0-20,000 USDC to earn 12%APY in USDT, USDC or 12% APR in PRV. Invest >20,000 USDT or USDC to earn 4% APY in USDT, USDC or 7% APR in PRV.</p>
  //   `
  // },
];

const renderDescription = item => {
  if (React.isValidElement(item) || typeof item === 'string') return item;
  return null;
};

const Faqs = () => {
  const cryptoLendRate = useSelector(state => state.app.contentVariables.crypto_lend_rate);
  const cryptoLendRateStable = useSelector(state => state.app.contentVariables.crypto_lend_stablecoin_rate);
  const prvCryptoLendRate = useSelector(state => state.app.contentVariables.crypto_lend_prv_rate);
  const prvCryptoLendRateStable = useSelector(state => state.app.contentVariables.crypto_lend_stablecoin_prv_rate);
  const flexCollateralsList = useSelector(state => state.app.flexCollaterals);

  const [cryptoLendRateMembership] = useCryptoInterest({apyRate: cryptoLendRate});
  const [cryptoLendRateStableMembership] = useCryptoInterest({apyRate: cryptoLendRateStable});
  const [prvCryptoLendRateMembership] = useCryptoInterest({apyRate: prvCryptoLendRate});
  const [prvCryptoLendRateStableMembership] = useCryptoInterest({apyRate: prvCryptoLendRateStable});

  return (
    <FaqsSection data={CryptoLendFaqData({cryptoLendRate: cryptoLendRateMembership, prvCryptoLendRate: prvCryptoLendRateStableMembership, flexCollateralsList, cryptoLendRateStable: cryptoLendRateStableMembership}).map(item => ({
      ...item,
      answer: <GlossaryLang displayHtml={renderDescription(item.answer)} />,
    }))}
    />
  );
};

export default Faqs;
