/* eslint-disable react/require-default-props */
import React from 'react';
import { compose } from 'redux';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { API_ROOT, URL } from 'src/resources/constants/url';
import { HomeFaqData } from 'src/screens/newHome/faqs';
import { SignUpFaqData } from 'src/screens/register/faqs';
import { InvestFaqData } from 'src/screens/invest/faqs';
import { CryptoLendFaqData } from 'src/screens/cryptoLend/faqs';
import { BorrowFaqData } from 'src/screens/constantLoan/faqs';
import { CryptoShortFaqData } from 'src/screens/cryptoShort/faqs';
import { BadgesFaqData } from 'src/screens/badges/faq';
import { MembershipFaqData } from 'src/screens/memberShip/faqs';
import { GiftCardFaqData } from 'src/screens/giftCards/faqs';
import ConversationPages from 'src/server/conversationPages';

import seo from './seo';
import {
  faqRichSnippet,
  organizationRichSnippet,
  productRichSnippet,
  softwareApplicationRichSnippet,
} from './richSnippet';
import useInvestInterest from 'src/hooks/useInvestInterest';
import useBorrowInterest from 'src/hooks/useBorrowInterest';

const MyHelmet = props => {
  const { page, extra, match, staticContext } = props;
  const [minTermRate] = useBorrowInterest({});
  const [maxTermRate] = useInvestInterest({});

  const pageKey = page || 'default';
  const rate = pageKey === 'invest'
    ? maxTermRate
    : pageKey === 'borrow' ? minTermRate : '';
  const seoData = seo[pageKey] ? seo[pageKey] : seo['homepage'];

  const removeLastSlash = url => {
    const length = url.length;
    if (length > 1 && url[length-1] === '/') return url.slice(0, -1);
    return url;
  };

  const capitalize = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const getCanonical = () => {
    if (page === 'loanOriginator') return `${API_ROOT}/loan-orginators`;
    if (page === 'notFound') {
      if (staticContext?.url) return `${API_ROOT}${staticContext?.url}`;
      if (__CLIENT__) return location?.href;
    }
    if (page === 'signUp') {
      return `${API_ROOT}${URL.USER_SIGN_UP}`;
    }
    return `${API_ROOT}${removeLastSlash(match.url)}`;
  };

  const meta = {
    title: seoData.title,
    description: seoData?.description?.replace('{rate}', rate),
    keywords: seoData.keyword,
    canonicalUrl: getCanonical(),
    image: pageKey === 'cryptoLendSignUp' ? 'https://www.myconstant.com/dist/web/assets/thumbnail_lending.png' : 'https://www.myconstant.com/dist/web/assets/constant.png',
    name: seoData.name,
    robotContent: seoData.robotContent || 'index,follow',
  };

  if (pageKey === 'forum' && extra?.topic) {
    const topic = capitalize(extra?.topic);
    meta.title = seo['forumTopic'].title.replace(/\{topic\}/g, topic);
    meta.description = seo['forumTopic'].description.replace(/\{topic\}/g, topic);
    if (extra?.page) {
      meta.title = seo['forumTopicPage'].title.replace(/\{topic\}/g, topic).replace(/\{page\}/g, extra?.page);
      meta.description = seo['forumTopicPage'].description.replace(/\{topic\}/g, topic).replace(/\{page\}/g, extra?.page);
      Object.keys(ConversationPages).forEach(i => {
        if (i === String(extra?.page)) {
          meta.title = ConversationPages[i].title;
          meta.description = ConversationPages[i].desc;

        }
      });
    }
  } 

  const isHome = () => {
    return pageKey === 'homepage';
  };

  const isProductPage = () => {
    return ['proLending', 'depositFiat', 'invest', 'depositCrypto', 'cryptoLend', 'borrow', 'shortSelling'].includes(pageKey);
  };

  const isHaveFaq = () => {
    return ['homepage', 'signUp', 'invest', 'cryptoLend', 'borrow', 'shortSelling', 'badges', 'membership', 'giftCard'].includes(pageKey);
  };

  const getFaqData = () => {
    switch (pageKey) {
      case 'homepage':
        return HomeFaqData;
      case 'signUp':
        return SignUpFaqData({});
      case 'invest':
        return InvestFaqData;
      case 'cryptoLend':
        return CryptoLendFaqData({});
      case 'borrow':
        return BorrowFaqData;
      case 'shortSelling':
        return CryptoShortFaqData;
      case 'badges':
        return BadgesFaqData;
      case 'membership':
        return MembershipFaqData;
      case 'giftCard':
        return GiftCardFaqData;
    }
  };

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <meta name="thumbnail" content={meta.image}/>

      <link rel="canonical" href={meta.canonicalUrl} />
      {!!extra?.prev && <link rel="prev" href={extra?.prev} />}
      {!!extra?.next && <link rel="next" href={extra?.next} />}

      <meta name="robots" content={meta.robotContent} />

      <meta name="geo.region" content="US-CA" />
      <meta name="geo.position" content="33.894289;-117.279592" />
      <meta name="ICBM" content="33.894289, -117.279592" />

      <meta property="og:url" content={meta.canonicalUrl} />
      <meta property="og:title" content={meta.title} />
      <meta property="og:description" content={meta.description} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content="MyConstant" />
      <meta property="og:image" content={meta.image} />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:alt" content="MyConstant logo - P2P Lending Platform" />
      <meta property="og:country-name" content="USA" />
      <meta property="og:locale" content="en_US" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@myconstantp2p" />
      <meta name="twitter:title" content={meta.title} />
      <meta name="twitter:description" content={meta.description} />
      <meta name="twitter:image" content={meta.image} />
      <meta name="twitter:image:alt" content="MyConstant logo - P2P Lending Platform" />

      <script type="application/ld+json">{JSON.stringify(organizationRichSnippet())}</script>
      {isHome() && <script type="application/ld+json">{JSON.stringify(softwareApplicationRichSnippet())}</script>}
      {isProductPage() && <script type="application/ld+json">{JSON.stringify(productRichSnippet(meta, `${API_ROOT}${match.url}`))}</script>}
      {isHaveFaq() && <script type="application/ld+json">{JSON.stringify(faqRichSnippet(`${API_ROOT}${match.url}`, getFaqData()))}</script>}
    </Helmet>
  );
};

MyHelmet.propTypes = {
  page: PropTypes.string,
  extra: PropTypes.object,
};

export default compose(
  withRouter,
)(MyHelmet);
