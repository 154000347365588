export default {
  default: {
    title: 'Invest in P2P Lending with USD or Crypto - MyConstant',
    description: 'Among peer-to-peer lending websites for online investments in the USA, MyConstant is a great lending platform for you to invest directly online today.'
  },
  homepage: {
    title: 'Invest in P2P Lending with USD or Crypto - MyConstant',
    description: 'Among peer-to-peer lending websites for online investments in the USA, MyConstant is a great lending platform for you to invest directly online today.',
  },
  borrow: {
    title: 'Get A Crypto-Backed Loan Against Multi-Coin Collateral - MyConstant',
    description: 'Borrow against cryptos on MyConstant. Free withdraws, fully collateralized loans, and up to 70+ coins to borrow and trade at your fingertips.',
    name: 'Crypto-Backed Loans',
  },
  invest: {
    title: 'Lend Money Online (upto 8% APR) by Investing in P2P Loans - MyConstant',
    description: 'Lend money online for interest at Myconstant to earn up to 8% APR on your crypto-backed investments. This makes for a great alternative to certificate of deposits.',
    name: 'Lend Money Online - MyConstant',
  },
  shortSelling: {
    title: 'Short Selling Cryptos When Market Dip To Earn High Reward - MyConstant',
    description: 'Predict a bear market? Earn big when prices drop. High risk. High reward.',
    name: 'Short Selling - MyConstant',
  },
  badges: {
    title: 'Get the recognition you deserve with MyConstant Badges - MyConstant',
    description: 'Earn badges and cash rewards of up to $100 when you use or promote our platform. From passing KYC to referring friends, there’s a badge to recognize all of your achievements. How many will you earn?',
  },
  loanOriginator: {
    title: 'Loan Originators - Earn up to 11% APR Investing in Loans - MyConstant',
    description: 'My Constant works with the top loan originators in the world to give investors more choice and better returns. We offer loans with buyback guarantee to protect our investors'
  },
  cryptoLend: {
    title: 'Lend Crypto to Earn Interest Every Second, Withdraw Anytime - MyConstant',
    description: 'P2P crypto lending has never been easier than with MyConstant. When you open your crypto lending account, it earns interest every second and you can withdraw anytime.',
    name: 'Crypto Lending - MyConstant',
  },
  cryptoCredit: {
    title: 'Crypro Credit - Peer-to-Peer Lending Platform - MyConstant',
    description: 'Borrow cash against your crypto to buy new coins. You set the rate and term, we match you with an investor, and then deposit your chosen coin. No exchanges. No trading fees. Best rates.'
  },
  flex: {
    title: 'Enjoy 50x Better Interest than a Savings Account - MyConstant',
    description: 'Join our Instant-access investing lending pool to earn 4% APY on your investments. All lending is protected by collateral, and interest is compounded and paid every second. Withdraw anytime for free.'
  },
  aboutUs: {
    title: 'About Us - MyConstant',
    description: 'Let’s rewind to January 2019 when it all began…Back then, we were a humble stablecoin project. A team of economists, engineers, and creatives looking to make money more useful.',
  },
  pricing: {
    title: 'Fees and Service Times - MyConstant',
    description: 'While MyConstant processes your deposits and withdrawals free of charge, transaction fees may be applied separately by your financial institution.'
  },
  withdraw: {
    title: 'Withdraw USD - MyConstant',
    description: 'Withdraw funds to your bank account via Wire, or ACH. All withdrawals – are free and unlimited',
  },
  secondaryMarket: {
    title: 'Higher Interest Rate Investments with Secondary Lending Market - MyConstant',
    description: 'MyConstant\'s secondary market is the place where investors can buy and sell existing investments. Make an intial secondary market investment with us now.',
    name: 'Secondary Market - MyConstant',
  },
  signUp: {
    title: 'Sign Up to Get $4000 Free Trial Bonus in Your Balance - MyConstant',
    description: 'Sign up today to get a MyConstant $4,000 trial bonus in your balance. After you sign up, complete KYC, we\'ll lend you $4,000 so you can see your interest grow.',
  },
  signUpAffiliateBorrow: {
    title: 'Sign up and borrow 0% APR today - MyConstant!',
    description: 'Sign up and borrow today to get a MyConstant 0% APR for your loan order. After you sign up, 0% APR is auto applied to your first loan 30-day term so you can save your money and say bye to high interest rates to start next loan as low as 6% APR only.',
  },
  logIn: {
    title: 'Log in - MyConstant',
    description: 'On MyConstant, login to discover power in your wallet. Signing up to MyConstant is simple. Create a profile, complete KYC and deposit your funds to start earning today!',
  },
  forgetPassword: {
    title: 'Forget Password - MyConstant',
    description: 'Forget Password - MyConstant',
  },
  forgetPasswordFinish: {
    title: 'Forget Finish - MyConstant',
    description: 'Forget Finish - MyConstant',
  },
  whyPeopleLoveUs: {
    title: 'Why People Love Us - MyConstant',
    description: 'Find out what 1,000s of followers have to say about their MyConstant experience. Want to join the conversation? Leave your review here.',
  },
  affiliate: {
    title: 'Peer-to-Peer Lending Affiliate Finance Vertical - MyConstant',
    description: 'MyConstant\'s finance affiliate program is all about rewards. Affiliates get $20 for each person they refer and 20% of the referee\'s anytime instant access earnings for the first year.',
  },
  referral: {
    title: 'Share & Earn - Refer Friends to Get Reward - MyConstant',
    description: 'Tell your friends and family about us and get 10% of their anytime Instant-access investment interest. Refer as many friends as you like, and grow your money together.',
  },
  withdrawFees: {
    title: 'Fee schedule and collateral rules - MyConstant',
    description: 'Fee schedule and collateral rules',
  },
  callUs: {
    title: 'Call us - MyConstant',
    description: 'Give us a ring on +1 646 809 8338 or we’ll call you with all the help and answers you need.',
  },
  depositCrypto: {
    title: 'Get Multi-Cryptocurrency Wallet to Earn Interest  - MyConstant',
    description: 'Whether your crypto wallets be hacked? Get online multi crypto wallet at Myconstant now to mitgate risk and store your coins at the safest condition.',
    name: 'Online Multi-Crypto Wallet - MyConstant',
  },
  depositFiat: {
    title: 'Deposit Money Online to Earn Interest (4% APY), Paid Every Second - MyConstant',
    description: 'With bank-beating rate from MyConstant deposit, you deposit money to earn interest at 4% APY. Our anytme instant access account is a great alternative to a saving account.',
    name: 'Deposit Money Online - MyConstant',
  },
  transferCrypto: {
    title: 'Withdraw Cryptos & Stablecoins To Your Wallet',
    description: 'Send cryptocurrencies and stablecoins to any compatible wallet. Simply select the amount, crypto, and enter the wallet address. Click Send and your funds will be on their way to you',
  },
  transferFiat: {
    title: 'Transfer USD - Send Money To Anyone, Anywhere - MyConstant',
    description: 'All you need is an internet connection to make as many transfers as you like - any amount, anywhere in the world. Send money home, pay your international clients, transfer salaries to your distributed team',
  },
  helpAndSupport: {
    title: 'Help & Support - MyConstant',
    description: 'Give us a ring on +1 646 809 8338 or we\'ll call you with all the help and answers you need.',
  },
  helpAndSupportGeneral: {
    title: 'Help & Support - General - MyConstant',
    description: 'How to sign up, how to verify your ID (KYC), matching times, service times, and more.',
  },
  helpAndSupportInvesting: {
    title: 'Help & Support - Investing - MyConstant',
    description: 'How we protect your investment, ending your term early, loan liquidation, and more.',
  },
  helpAndSupportInvest: {
    title: 'Help & Support - Invest - MyConstant',
    description: 'How we protect your investment, ending your term early, loan liquidation, and more.',
  },
  helpAndSupportBorrowing: {
    title: 'Help & Support - Borrowing - MyConstant',
    description: 'How we protect your collateral, what happens if you default, early repayment, and more.',
  },
  helpAndSupportBorrow: {
    title: 'Help & Support - Borrow - MyConstant',
    description: 'How we protect your collateral, what happens if you default, early repayment, and more.',
  },
  helpAndSupportAffiliate: {
    title: 'Help & Support - Affiliate - MyConstant',
    description: 'How to become an affiliate, what you\'ll earn with us, and how we can support you.',
  },
  helpAndSupportCryptolend: {
    title: 'Help & Support - Cryptolend - MyConstant',
    description: 'How it works, supported cryptos, and benefits.',
  },
  helpAndSupportPro: {
    title: 'Help & Support - Pro - MyConstant',
    description: 'How to use Pro, secondary market orders, how to find the best deals, and more.',
  },
  helpAndSupportSecurity: {
    title: 'Help & Support - Security - MyConstant',
    description: 'How we protect your funds and collateral, custodial partners, insurance, and more',
  },
  helpAndSupportDeposit: {
    title: 'Help & Support - Deposit - MyConstant',
    description: 'Transfer methods, timings, and more.',
  },
  helpAndSupportFlex: {
    title: 'Help & Support - Instant-access investing - MyConstant',
    description: 'How Instant-access investing works, calculating interest, anytime withdrawals, Compound Finance, and more.',
  },
  helpAndSupportMembership: {
    title: 'Help & Support - Membership - MyConstant',
    description: 'How membership works, how to earn points, benefits, eligibility criteria, and more.',
  },
  agreements: {
    title: 'Agreements - MyConstant',
    description: 'Our Terms of Service',
  },
  promotion: {
    title: 'Promotion - MyConstant',
    description: 'Come and join our promotion program',
  },
  securityRisk: {
    title: 'Security & Risk - Loan Originators - MyConstant',
    description: 'It’s a bit daunting when you first start out. There are so many investments to choose from and all that legal and technical jargon is enough to make your head spin!',
  },
  onlineSecurity: {
    title: 'Online Security - Loan Originators - MyConstant',
    description: 'Online security is a shared responsibility. It involves you, us, and every other internet entity on the planet. There’s no room for complacency.',
  },
  loLanding: {
    title: 'Loan Originators - Earn up to 11% APR Investing in Loans - MyConstant',
    description: 'My Constant works with the top loan originators in the world to give investors more choice and better returns. We offer loans with buyback guarantee to protect our investors',
  },
  loHowItWork: {
    title: 'How it works - Loan Originators - MyConstant',
    description: 'Diversify your investments and multiply your returns across global lending markets',
  },
  loLoanOriginators: {
    title: 'Listing - Loan Originators - MyConstant',
    description: 'Choose an Investment Plan (Auto Invest) for easy investing or follow your own strategy (Manual Invest)',
  },
  loRating: {
    title: 'Ranking - Loan Originators - MyConstant',
    description: 'Before you invest in Loan Originator, please take a minute to review our rating system. We rate loan originators from A to D, with A being the best, and only ever accept loan originators rated C and above',
  },
  membership: {
    title: 'Membership Rewards - MyConstant',
    description: 'Get exclusive platform benefits the more you invest or borrow',
  },
  statistic: {
    title: 'Our Results So Far - MyConstant',
    description: 'Below, you’ll find our historical performance statistics. These numbers represent investor and borrower activity to date',
  },
  giftCard: {
    title: 'Buy E-Gift Cards Online with 3% Cashback - MyConstant',
    description: 'Use your MyConstant balance to get deals on your favorite brands online or in stores with gift cards. Select your card and amount and spend even from your phone.',
  },
  registerAccreditation: {
    title: 'Register Your Accreditation - MyConstant',
    description: 'We require proof of accreditation from you that meets the more stringent standard the SEC has put in place – the 506(c) standard – which should work for all accredited deals, including ones that publicly discuss their financing',
  },
  collateralBalance: {
    title: 'Coin Balance - MyConstant',
    description: 'Let\'s see how your money is doing today',
  },
  prizeWheel: {
    title: 'Prize Wheel - Cash, Point Rewards - MyConstant',
    description: 'Right up until 10 January, you can win up to $1,000 in cash, membership points, and more in our prize giveaway',
  },
  prizeWheelFinish: {
    title: 'Thank You For Spinning Our Birthday - Prize Wheel - MyConstant',
    description: 'It’s been great reading your birthday wishes and we hope you’ve enjoyed your prizes. Keep your eye out for other giveaways in the future',
  },
  me: {
    title: 'Me - MyConstant',
    description: 'Me - MyConstant',
    robotContent: 'noindex,follow',
  },
  yourAccount: {
    title: 'Accounts - MyConstant',
    description: 'Let\'s see how your money is doing today',
    robotContent: 'noindex,follow',
  },
  notification: {
    title: 'Notifications - MyConstant',
    description: 'Notifications - MyConstant',
    robotContent: 'noindex,follow',
  },
  thankYouSignUp: {
    title: 'Thank You For Your Signup - MyConstant',
    description: 'Thanks for signing up to MyConstant. We’ve sent a verification link to your email address. Please click this link to finish setting up your account.',
    robotContent: 'noindex,follow',
  },
  unsubscribe: {
    title: 'Unsubscribe Email - MyConstant',
    description: 'Are you sure you want to unsubscribe from all marketing emails? You\'ll miss out on special offers, exclusive interest deals, promotions, investing news, and lots more.',
    robotContent: 'noindex,follow',
  },
  emailConfirmReserve: {
    title: 'Email Confirm Reserve - MyConstant',
    description: 'Email Confirm Reserve - MyConstant',
    robotContent: 'noindex,follow',
  },
  emailConfirmWithdrawCrypto: {
    title: 'Email Confirm Crypto Withdrawal - MyConstant',
    description: 'Email Confirm Crypto Withdrawal - MyConstant',
    robotContent: 'noindex,follow',
  },
  activateAccount: {
    title: 'Active Account - MyConstant',
    description: 'Active Account - MyConstant',
    robotContent: 'noindex,follow',
  },
  notFound: {
    title: 'Page not found - 404 Error - MyConstant',
    description: 'Page not found, MyConstant.com,'
  },
  liveChat: {
    title: 'MyConstant Live Chat - Customer Service - MyConstant',
    description: 'Here you\'ll find answers to the most common questions our customers ask. We\'ll be happy to help you all',
  },
  cryptoFuture: {
    title: 'Predict Crypto Prices to Win Cash - MyConstant',
    description: 'Do you have a knack for predicting the swings of the crypto market? If you do, you can turn your predicting powers into cash with our new crypto price prediction feature.'
  },
  cryptoFutureDemo: {
    title: 'Trial new our Feature - Predict Crypto Prices - MyConstant',
    description: 'This is the trial version for Predict Crypto Prices feature.'
  },
  personalBudget: {
    title: 'Personal Budget - Earn, Save, and Manage Money - MyConstant',
    description: 'Acheieve your financial goals fast with Personal Budget. Sync budgets to your bank account so you never miss a transaction. Set up overspend reminders to stay on traget. No receipts. No spreadsheets. Available in the MyConstant app now.'
  },
  forum: {
    title: 'Start conversations with us - MyConstant',
    description: 'Start conversations with us',
  },
  forumTopic: {
    title: 'Conversations - {topic} - MyConstant',
    description: 'Let join the topic {topic} with MyConstant',
  },
  forumTopicPage: {
    title: 'Topic {topic} - Page {page} - MyConstant',
    description: 'Topic {topic} - Page {page}',
  },
  debitCard: {
    title: 'MyConstant’s debit card gives you the freedom to spend wherever and whenever you want, with security and privacy.',
    description: 'Your debit card allows you to spend your money online or pay your bills whenever you want with no hidden fees. When your money isn’t on your card, it earns 4% APY through your instant-access account. Plus, your fixed-term investments give you a line of credit of up to $2,000 with no interest for 30-days.',
  },
  cryptoSwap: {
    title: 'Swap your crypto to maximize your investments - a choice of 70+ cryptos to choose from',
    description: 'Crypto Swap enables you to exchange and trade your crypto to earn more at any time. With only a 0.1% trading fee and over 70 cryptocurrencies to choose from, you have the opportunity to take full advantage of market movements.',
  },
  forumChat: {
    title: 'Start conversations with us - MyConstant',
    description: 'Start conversations with us',
  },
  constantToken: {
    title: 'About MCT | MyConstant Token',
    description: 'Your bridge between the metaverse and traditional banking; MCT Token. Invest, borrow, and trade fiat, cryptos.',
    name: 'MCT - MyConstant',
  },
  airdropWheel: {
    title: 'The Airdrop Prize Wheel - $MCT Airdrop -  MyConstant',
    description: 'Join our Airdrop and you can earn free MCT tokens. Don’t wait—now’s your chance to be one of the first in the world to hold MCT.',
  },
  cryptoLendSignUp: {
    title: 'Lend USDT to Earn Interest Every Second, Withdraw Anytime - MyConstant',
    description: 'P2P crypto lending has never been easier than with MyConstant. When you open your crypto lending account, it earns interest every second and you can withdraw anytime.',
    name: 'Crypto Lending - MyConstant',
  },
  mctAirdropWheel: {
    title: 'The Prize Wheel - Earn up to $10,000 with MyConstant',
    description: 'Join our Prize Wheel and you can win up to $10,000. Don\'t wait—now\'s your chance to earn cash prizes and MCT Tokens.',
  },
  mctMascot: {
    title: 'Battle Boars NFT Collection - MyConstant\'s Mascots',
    description: 'This NFT Collection represents the MyConstant team',
    name: 'Battle Boars Mascot - MyConstant',
  },
};
