import React from 'react';
import FaqsSection from 'src/modules/faqs';
import { MAX_SHORT_VALUE, MIN_SHORT_VALUE } from 'src/resources/constants/app';
import { formatMoneyByLocale } from 'src/utils/format/currency';

export const CryptoShortFaqData = [
  {
    question: 'What is short selling?',
    answer: `
    <p>Short selling is a high-risk trading strategy that involves borrowing an asset you believe will fall in price and then pocketing the difference when you repay.</p>
    <p>For example, if you borrow an asset worth $1,000 and then sell it on the market, you have $1,000. If that asset falls to $900, you can then buy it again at the lower price, repay the lender, and keep the $100 difference.</p>
    <p>However, if your prediction is wrong and the asset increases in price, you’ll have to pay more to repay the asset. This can result in some fast and significant losses if you’re not careful.</p>
    `,
  },
  {
    question: 'How does short selling work?',
    answer: `
    <p>MyConstant can lend you crypto through three pairs:</p>
    <ul><li>USD / BTC</li><li>USD / ETH</li><li>USD / BNB</li></ul>
    <p>To short any of these cryptocurrencies, you must first top up your chosen pair’s balance.</p>
    <p>If you already have USD, it’s as simple as transferring it from your available balance to your chosen short-selling balance. Otherwise, you’ll need to deposit first.</p>
    <p>Next, you choose how much of your chosen crypto you want to short. Remember – your collateralized USD is at risk if the crypto increases in price, so only short what you can afford.</p>
    <p>You then need to set a stop loss and take profit limit. These are lower and upper price limits of the crypto that can help you secure profits and minimize losses.</p>
    <p>With these values set, you’re ready to hit the “Short Crypto Now” button.</p>
    <p>It’s then a matter of waiting to see how the market behaves – will your bet pay off? We hope so!</p>
    <p>You can wait until you hit your “take profit” limit or manually repay your crypto whenever you like, as either USD from your available short-selling balance or as crypto from your coin balance.</p>
    <p>However, if the price of your shorted crypto rises, your collateral will be at risk. Once your collateral value falls to 105%, it’s sold and you lose it.</p>
    `,
  },
  {
    question: 'What is the “take profit” price?',
    answer: `
    <p>Your take profit price is the price at which you repay your shorted cryptocurrency.</p>
    <p>You set a price lower than the current market price and when the market hits that level, you repay your shorted cryptocurrency and keep the profits.</p>
    <p>For example, let’s say you’re shorting bitcoin. It’s current price is $50,000. If you believe it will fall 10%, you’d set your take-profit price to $45,000.</p>
    <p>When bitcoin’s price hits $45,000, you repay the borrowed cryptocurrency in its equivalent value in USD from your short-selling balance. You keep the profits minus the matching fee and interest rate.</p>
    `,
  },
  {
    question: 'What is the “stop loss” price?',
    answer: `
    <p>Similar to the take-profit limit, the stop-loss limit is a price above market at which you automatically repay the shorted cryptocurrency.</p>
    <p>Let’s say you’re shorting bitcoin and its current price is $50,000. If the price rises 10% to $55,000, your collateral is at risk because you now owe more than you borrowed.</p>
    <p>If you want to minimize your losses and protect your USD collateral, you must set a stop loss. However, you don’t want to set it too low as prices often fall as quickly as they rise, and you don’t want your short knocked out because of standard market volatility.</p>
    <p>While the stop loss is a specific cryptocurrency price, we show you the expected loss in USD if the price hits your stop loss value. Make sure you’re comfortable with this number.</p>
    `,
  },
  {
    question: 'Which cryptocurrencies can I short?',
    answer: `
    <p>At the moment, you can short BTC, ETH, BNB and more.</p>
    `,
  },
  {
    question: 'Are there any fees for short selling?',
    answer: `
    <p>Yes, there’s the matching fee of 1% and you pay 7% APR on your shorted cryptocurrency until you repay.</p>
    `,
  },
  {
    question: 'Is short selling risky?',
    answer: `
    <p>Yes, short selling is a high-risk yet high-reward trading strategy. On MyConstant, you’re a little safer than other platforms as you can only lose what USD you put down as collateral. As long as you don’t “chase gains” by topping up this collateral often when your shorted crypto increases in price, you are in a better position to control losses.</p>
    `,
  },
  {
    question: 'What are the benefits of short selling?',
    answer: `
    <p>Short selling is a useful hedge when you’re unsure of where the market is going. While traditional investing expects prices to rise, short selling pays when prices fall. It’s therefore a useful strategy in times of uncertainty or bear markets. It’s also used as a speculative investment to bet against the market. Whenever you short sell, remember the risks and ensure you set a comfortable stop loss.</p>
    `,
  },
  {
    question: 'How can I minimize risk when short selling?',
    answer: `
    <p>Only invest what you can afford to lose. Set an appropriate stop loss. Don’t chase gains by regularly topping up your USD collateral to avoid liquidation.</p>
    `,
  },
  {
    question: 'How often do you pull crypto prices?',
    answer: `
    <p>Crypto prices are refreshed every few seconds. If the price changes significantly before you've completed your order, you'll need to do it again to get the latest price. (You have a few minutes to open your short before this happens.)</p>
    `,
  },
  {
    question: 'How often is interest calculated?',
    answer: `
    <p>Interest is calculated every few seconds. Should any significant change in the interest due happen while you're in the process of repaying, you might be asked to refresh the page and try again.</p>
    `,
  },
  {
    question: 'What\'s the maximum I can short?',
    answer: `
    <p>The maximum you can short for all supported cryptocurrencies is the equivalent of $${formatMoneyByLocale(MAX_SHORT_VALUE)}. This is to avoid destabilizing the market, which in turn may lead to losses.</p>
    `,
  },
  {
    question: 'Can I transfer USD from one short-selling balance to another?',
    answer: `
    <p>No, I'm afraid not. You can only transfer in and out of your short-selling balance and account balance, not between different pairs (for example, you can't transfer USD from a BTC pair to an ETH pair).</p>
    `,
  },
  {
    question: 'What\'s the minimum to open a short position?',
    answer: `
    <p>The minimum to open a short position is the crypto equivalent of $${MIN_SHORT_VALUE}.</p>
    `,
  },
];

const renderDescription = item => {
  if (React.isValidElement(item) || typeof item === 'string') return item;
  return null;
};

const Faqs = () => {
  return (
    <FaqsSection data={CryptoShortFaqData.map(item => ({
      ...item,
      answer: (
        <span dangerouslySetInnerHTML={{ __html: renderDescription(item.answer) }} />
      ),
    }))}
    />
  );
};

export default Faqs;
