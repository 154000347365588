import { reviews, numReview, pointRating } from 'src/components/trustPilot/data';
import { stripHtml } from 'src/utils/common';
import seo from './seo';

export const organizationRichSnippet = () => {
  const obj = {
    '@context': 'https://schema.org/',
    '@type': 'Organization',
    'name': 'MyConstant',
    'legalName': 'MyConstant',
    'alternateName': 'MyConstant',
    'brand': 'MyConstant',
    'url': 'https://www.myconstant.com/',
    'logo': 'https://www.myconstant.com/dist/node/assets/favicon.png',
    'slogan': seo['homepage'].title,
    'description': seo['homepage'].description,
    'image':  'https://www.myconstant.com/dist/node/assets/bg9-f7c61f26.png',
    'email': 'hello@myconstant.com',
    'foundingDate': '6th January, 2019',
    'contactPoint': {
      '@type': 'ContactPoint',
      'contactType': 'Customer support',
      'telephone': '+1 646 809 8338',
      'email': 'hello@myconstant.com'
    },
    'sameAs':
    [
      'https://www.myconstant.com/',
      'https://twitter.com/myconstantp2p',
      'https://myconstantp2p.medium.com',
      'https://t.me/constantp2p',
    ],
    'address': {
      '@type': 'PostalAddress',
      'streetAddress': '21800 Opportunity Way',
      'addressLocality': 'Riverside',
      'addressRegion': 'CA',
      'postalCode': '92518',
      'addressCountry': 'USA'
    },
    'aggregateRating':{
      '@type':'AggregateRating',
      'ratingValue': pointRating,
      'reviewCount': numReview
    },
    'review': Object.values(reviews).map(e => ({
      '@type': 'Review',
      'author': e.auth,
      'datePublished': e.date,
      'reviewBody': e.desc,
    })),
  };
  return obj;
};

export const softwareApplicationRichSnippet = () => {
  return {
    '@context': 'https://schema.org',
    '@type': 'SoftwareApplication',
    'name': 'MyConstant App',
    'image': 'https://www.myconstant.com/dist/node/assets/favicon.png',
    'url': 'https://www.myconstant.com/',
    'author': 'MyConstant',
    'datePublished': '6th January, 2019',
    'publisher': {
      '@type': 'Organization',
      'name': 'MyConstant',
    },
    'applicationCategory': 'FinanceApplication',
    'downloadUrl': ['https://play.google.com/store/apps/details?id=com.constantapp&hl=en','https://apps.apple.com/us/app/myconstant-p2p-lending/id1497107082?ls=1'],
    'operatingSystem': 'ANDROID, IOS',
    'screenshot': 'https://www.myconstant.com/dist/web/assets/websiteMobile-c264d912.png',
    'storageRequirements': '68M',
    'description': 'You choose the interest rate. You choose the term. We match you with someone happy to do business with you.',
    'aggregateRating': {
      '@type': 'AggregateRating',
      'ratingValue': pointRating, //kéo value tương ứng
      'ratingCount': numReview,
    }, //kéo value tương ứng
    'offers': {
      '@type': 'Offer',
      'price': '0',
    },
  };
};

export const productRichSnippet = (seo, url) => {
  return {
    '@context': 'http://schema.org',
    '@type': 'Product',
    'name': seo.name,
    'image': 'https://www.myconstant.com/dist/node/assets/favicon.png',
    'url': url,
    'description': seo.description,
    'brand':  {
      '@type': 'Brand',
      'name': 'MyConstant',
    },
    'offers': {
      '@type': 'Offer',
      'name': seo.name,
      'availability': 'https://schema.org/OnlineOnly',
      'price': 0,
      'priceCurrency': 'USD',
      'url': url,
    },
    'provider': {
      '@type': 'LocalBusiness',
      'name': 'MyConstant',
      'telephone': '+1 646 809 8338',
      'priceRange': '$$$',
      'image': 'https://www.myconstant.com/dist/node/assets/favicon.png',
      'address': {
        '@type': 'PostalAddress',
        'streetAddress': '21800 Opportunity Way',
        'postalCode': '92518',
        'addressRegion': 'CA',
        'addressLocality': 'Riverside',
        'addressCountry': 'USA',
      },
      '@id': 'https://www.myconstant.com/',
    },
    'aggregateRating': {
      '@type':'AggregateRating',
      'itemReviewed': {
        '@type': 'Organization',
        'name': 'MyConstant',
      },
      'ratingValue': pointRating,
      'reviewCount': numReview,
    },
    'review': Object.values(reviews).map(e => ({
      '@type': 'Review',
      'author': e.auth,
      'datePublished': e.date,
      'reviewBody': e.desc,
    })),
  };
};

export const faqRichSnippet = (url, faqArray) => {
  if (!Array.isArray(faqArray)) return {};
  const shortArray = faqArray.slice(0, 3);
  return {
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    'url': url,
    'mainEntity': shortArray.map(e => {
      return {
        '@type': 'Question',
        'name': e.question,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': stripHtml(e.answer),
        }
      };
    }),
  };
};